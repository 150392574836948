import { useState } from "react"

const useModal = ({ scrollToTop = true } = {}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const toggleModalVisibility = () => {
    setIsModalVisible((s) => !s)
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }

  const setModalVisibility = (isVisible) => {
    setIsModalVisible(isVisible)
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }

  return {
    isModalVisible,
    toggleModalVisibility,
    setModalVisibility,
  }
}

export default useModal
