export const interviewSteps = [
  {
    stepId: 1,
    title: "Get to know you",
    content:
      "We are interested in learning more about you, your goals and why you are motivated to learn to code.",
  },
  {
    stepId: 2,
    title: "Ask us anything",
    content:
      "This is your chance to ask all the burning questions about learning to code at Launch Academy. Fire away!",
  },
  {
    stepId: 3,
    title: "Lightning Talk",
    content:
      "For 5 minutes teach us something for which you are passionate about. We’ll be assessing your ability to explain a subject.",
  },
  {
    stepId: 4,
    title: "Logic Questions",
    content:
      "We'll work on logic questions together to gain visibility into your problem solving process and how you solve problems collaboratively.",
  },
]
