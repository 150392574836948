import React from "react"

const InterviewAgendaTile = ({ interviewStep }) => {
  return (
    <div className="interview-steps__tile">
      <h3 className="interview-steps__header">
        {interviewStep.stepId}. {interviewStep.title}
      </h3>
      <p className="interview-steps__content">{interviewStep.content}</p>
    </div>
  )
}

export default InterviewAgendaTile
