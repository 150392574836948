import React from "react"

import InterviewAgendaTile from "./InterviewAgendaTile"
import { interviewSteps } from "../../pageData/applicationPageData"

import "./css/interviewAgenda.css"

const InterviewAgenda = () => {
  const agendaTiles = interviewSteps?.map((step) => (
    <InterviewAgendaTile key={step.title} interviewStep={step} />
  ))

  return (
    <div className="agenda-list">
      <h2 className="agenda-list__header">
        An Interview Agenda
        <br /> Built for Humans
      </h2>
      <div className="interview-steps">{agendaTiles}</div>
    </div>
  )
}

export default InterviewAgenda
