import React, { useMemo } from "react"

import Modal from "../../modal/Modal"
import useModal from "../../modal/useModal"
import InterviewAgenda from "../InterviewAgenda"

const useInterviewAgendaModal = () => {
  const { isModalVisible, setModalVisibility } = useModal({ scrollToTop: false })

  const modal = useMemo(() => {
    return (
      <Modal
        size="large"
        isVisible={isModalVisible}
        hide={() => {
          setModalVisibility(false)
        }}
      >
        <InterviewAgenda />
      </Modal>
    )
  }, [setModalVisibility, isModalVisible])

  return { modal, setModalVisibility }
}

export default useInterviewAgendaModal
