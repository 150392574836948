import React, { useState, useCallback } from "react"

import { InlineWidget, useCalendlyEventListener } from "react-calendly"

import Configuration from "../../Configuration"
import useAgendaModal from "../../components/application/hooks/useInterviewAgendaModal"

import "../css/application.css"

const pageSettings = {
  hideLandingPageDetails: true,
  hideEventTypeDetails: true,
}

const Apply = ({ location }) => {
  const [iframeHeight, setIFrameHeight] = useState(600)
  const { modal, setModalVisibility } = useAgendaModal()

  const showAgenda = useCallback(
    (e) => {
      e.preventDefault()
      setModalVisibility(true)
    },
    [setModalVisibility],
  )

  useCalendlyEventListener({
    onDateAndTimeSelected: (e) => {
      e.preventDefault()
      setIFrameHeight(1475)
    },
  })

  return (
    <div className="application-container">
      <h2 className="application-container__title">Start your Application</h2>

      <p className="application-container__text">
        Begin by scheduling your 1-hour Zoom interview below.
        <br /> It's best to set aside 60 minutes where you can be uninterrupted on a device with a
        camera.
      </p>

      <p className="application-container__text">
        As a friendly reminder, no coding challenges are required for acceptance into the program.
      </p>

      <a href="#" onClick={showAgenda} className="application-container__modal-button">
        What happens during an interview?
      </a>
      {modal}

      <InlineWidget
        url={Configuration.infoCallCalendlyUrl}
        pageSettings={pageSettings}
        styles={{ height: iframeHeight, borderRadius: "2rem" }}
      />
    </div>
  )
}

export default Apply
